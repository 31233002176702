<template>
  <el-dialog v-dragDialog title="所属角色" :visible.sync="viewVisible" width="557px" :close-on-click-modal="false">
    <el-table border height="300px" size="mini" :data="datas" highlight-current-row expand-on-click-node="false" style="width: 100%" :header-row-style="{
        height: '30px;',
        'background-color': '#4682B4',
        color: '#FFFFFF',
      }" :header-cell-style="{
        height: '30px;',
        'background-color': '#4682B4',
        color: '#FFFFFF',
      }">
      <el-table-column label="角色" prop="Name" min-width="150" align="left"></el-table-column>
      <el-table-column label="授权" prop="Grant" min-width="150" align="left">
        <template slot-scope="scope">
          <el-switch @change="changeGrant(scope.row)" v-model="scope.row.Grant" />
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="viewVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getAll } from "@/api/setting/role";
import {
  getMemberRoles,
  addMemberRole,
  deleteMemberRole,
} from "@/api/setting/member";
export default {
  data () {
    return {
      datas: [],
      viewVisible: false,
      userKey: "",
    };
  },
  methods: {
    show (userKey) {
      this.userKey = userKey;
      this.getMemberRoleGrantData();
      this.viewVisible = true;
    },
    getMemberRoleGrantData () {
      let para = {
        key: this.userKey,
      };
      getMemberRoles(para).then((response) => {
        if (response) {
          this.datas = response.data;
        }
      });
    },
    changeGrant (row) {
      let para = {
        roleKey: row.Key,
        userKey: this.userKey,
      };
      if (row.Grant) {
        addMemberRole(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
          }
        });
      } else {
        deleteMemberRole(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
          }
        });
      }
    },
  },
  mounted () { },
};
</script>
<style scoped>
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #606266;
  line-height: 24px;
  font-size: 14px;
}
</style>


