<template>
  <Treeselect
    placeholder="请选择门店"
    :normalizer="normalizer"
    :disableBranchNodes="false"
    showCount
    :defaultExpandLevel=1
    v-model="data"
    :multiple="false"
    @select="selectChanged"
    :options="datas"
    :disabled="disabled"
    :clearable="clearable"
  />
</template>
<script>
import { getTenantTree } from "@/api/setting/tenant";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
 components: {
    Treeselect
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    clearable: {
      default: true,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      datas: [],
      data: "",
    };
  },
  watch: {
    value: {
      handler(v) {
        this.data = v.Key;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.Key,
        label: node.Name,
        children: node.Children,
      };
    },
    getData() {
      getTenantTree({}).then((response) => {
        if (response) {
          this.datas = response;
        }
      });
    },
   selectChanged (v) {
      this.$emit("change", v)
    }
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang='scss' scoped>
.vue-treeselect__control
{
  height: 28px;
}
</style>