<template>
  <div class="userinfo-container">
    <section>
      <!--工具条-->
      <el-form :inline="true" :model="filters">
        <el-form-item style="margin-bottom: 0px" label="所属门店">
          <shopSelector style="width:200px;height:24px;" v-model="filters.tenant" auto-complete="off"/>
        </el-form-item>
        <el-form-item style="margin-bottom: 0px" label="关键字">
          <el-input size="small" v-model="filters.search" placeholder="登录名/姓名/手机/昵称/备注等关键词" style="width: 200px" @keyup.native.enter="getUserList"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0px">
          <el-button size="small" type="primary" @click="getUserList">查询</el-button>
          <el-button size="small" type="success" @click="create">新增</el-button>
        </el-form-item>
      </el-form>
      <div class="tabs-container">
        <el-tabs v-model="filters.source" @tab-click="typeChange">
          <el-tab-pane v-for="tab in tabs" :key="tab.key" :name="tab.key" :label="tab.name">
          </el-tab-pane>
        </el-tabs>
      </div>
      <!--列表-->
      <el-table stripe border size="mini" :data="users" highlight-current-row expand-on-click-node="false" v-loading="listLoading" style="width: 100%" :header-cell-style="{
          'font-weight': '400',
          height: '40px',
          padding: '0',
          background: '#4a8bc1',
          color: 'white',
        }">
        <el-table-column prop="Name" sortable label="登录名" min-width="120"></el-table-column>
        <el-table-column prop="RealName" sortable label="真实姓名" min-width="100"></el-table-column>
        <el-table-column prop="Actor.Name" sortable label="所在门店" min-width="100"></el-table-column>
        <el-table-column prop="Mobile" sortable label="手机号" min-width="100"></el-table-column>
        <el-table-column prop="Email" sortable label="邮箱" min-width="100"></el-table-column>
        <el-table-column prop="HeadImage" label="头像" width="80">
          <template slot-scope="scope">
            <viewer>
              <img v-if="scope.row.HeadImage" :src="
                  scope.row.HeadImage.indexOf('http') == 0
                    ? scope.row.HeadImage
                    : baseUrl + scope.row.HeadImage
                " :key="scope.row.HeadImage" width="30" />
            </viewer>
          </template>
        </el-table-column>
        <el-table-column label="用户类型" min-width="300">
          <template slot-scope="scope">
            <el-select size="small" style="width:150px;" v-model="scope.row.MemberType" @change="memberTypeChange(scope.row)">
              <el-option v-for="item in $config.memberType" :key="item.key" :value="item.value" :label="item.name"></el-option>
            </el-select>
            <el-switch @change="handleChange(scope.row)" v-if="scope.row.MemberType==10" v-model="scope.row.Alert" active-text="接收预约提醒"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="IsActive" sortable label="启用" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.IsActive" @change="handleChange(scope.row, 'IsActive')" active-color="#13ce66"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="InsertTime" sortable label="创建时间" width="130">
          <template slot-scope="scope">
            {{ format.dateTimeFormat(scope.row.InsertTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.Name != 'administrator'" size="small" @click="update(scope.row)">修改</el-button>
            <el-button type="text" v-if="scope.row.Name != 'administrator'" size="small" @click="showChangePwdForm(scope.row)">修改密码</el-button>
            <el-button type="text" v-if="scope.row.Name != 'administrator'" size="small" @click="remove(scope.row)">删除</el-button>
            <el-button type="text" v-if="scope.row.Name != 'administrator'" size="small" @click="role(scope.row)">所属角色</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizesList" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page.sync="pageIndex"></el-pagination>
      </div>
      <!--新增界面-->
      <div v-if="addFormVisible">
        <el-dialog v-dragDialog :title="textMap[dialogStatus]" :visible.sync="addFormVisible" width="500px" :close-on-click-modal="false">
          <el-form :model="addForm" ref="dataForm" label-width="130px" :rules="addFormRules">
            <el-form-item label="登录名：" prop="Name">
              <el-col :span="19">
                <el-input v-model="addForm.Name" auto-complete="off" v-bind:readonly="readonlyUserName" placeholder="请输入登录名"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="真实姓名：" prop="RealName">
              <el-col :span="19">
                <el-input v-model="addForm.RealName" auto-complete="off" placeholder="请输入真实姓名"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="所属门店：" prop="Actor">
              <el-col :span="19">
                <tenantSelector v-model="addForm.Actor" auto-complete="off" @change="tenantChanged" />
              </el-col>
            </el-form-item>
            <el-form-item label="密码：" prop="Password" v-if="dialogStatus == 'create'">
              <el-col :span="19">
                <el-input v-model="addForm.Password" type="password" auto-complete="off" placeholder="请输入密码"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="确认密码：" prop="Password2" v-if="dialogStatus == 'create'">
              <el-col :span="19">
                <el-input v-model="addForm.Password2" type="password" auto-complete="off" placeholder="请确认密码"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="手机号" prop="Mobile">
              <el-col :span="19">
                <el-input v-model="addForm.Mobile" auto-complete="off" placeholder="请输入手机号"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="邮箱" prop="Email">
              <el-col :span="19">
                <el-input v-model="addForm.Email" auto-complete="off" placeholder="请输入邮箱"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="备注：" prop="Description">
              <el-col :span="19">
                <el-input v-model="addForm.Description" type="textarea" auto-complete="off" placeholder="请输入备注"></el-input>
              </el-col>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="addFormVisible = false">取消</el-button>
            <el-button v-if="dialogStatus == 'create'" type="primary" @click="createSubmit">提交</el-button>
            <el-button v-else type="primary" @click="updateSubmit">提交</el-button>
          </div>
        </el-dialog>
      </div>
      <powerManager ref="powerManager" :showOption="true"></powerManager>
      <userRoleView ref="userRoleView"></userRoleView>
      <ChangePwd ref="pwdForm" />
    </section>
  </div>
</template>
<script>
import { getPageList, save, del, changeMemberType } from "@/api/setting/member";
import ChangePwd from "@/components/changePwd";
import powerManager from "@/components/powerManager";
import userRoleView from "@/components/userRoleView";
import imageUploader from "@/components/imageUploader";
import tenantSelector from "@/components/tenantSelector";
import shopSelector from '@/components/shopSelector.vue';
export default {
  components: {
    ChangePwd: ChangePwd,
    powerManager: powerManager,
    userRoleView: userRoleView,
    imageUploader: imageUploader,
    tenantSelector: tenantSelector,
    shopSelector:shopSelector,
  },
  data () {
    const pwdValidator1 = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error("请输入密码"));
      } else if (this.addForm.Password2 != "") {
        this.$refs.dataForm.validateField("Password2");
        callback();
      } else {
        callback();
      }
    };
    const pwdValidator2 = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.addForm.Password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      filters: {
        search: "",
        source: "ALL",
        tenant:"",
      },
      tabs: [
        { key: "ALL", name: "全部" },
        { key: "PC", name: "来自后台" },
        { key: "MP", name: "来自小程序" },
      ],
      readonlyUserName: false,
      users: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      listLoading: false,
      dialogStatus: "",
      addFormVisible: false,
      addLoading: false,
      textMap: {
        update: "修改用户",
        create: "新增用户",
      },
      addForm: {
        Key: "",
        Name: "",
        Actor: "",
        RealName: "",
        Mobile: "",
        Password: "",
        Password2: "",
        Description: "",
      },
      addFormRules: {
        Name: [
          { required: true, message: "用户名不能为空", trigger: "change" },
        ],
        Actor: [
          { required: true, message: "所属部门不能为空", trigger: "change" },
        ],
        RealName: [
          { required: true, message: "姓名不能为空", trigger: "change" },
        ],
        Mobile: [
          { required: true, message: "手机号不能为空", trigger: "change" },
        ],
        Password: [
          { required: true, validator: pwdValidator1, trigger: "change" },
        ],
        Password2: [
          { required: true, validator: pwdValidator2, trigger: "change" },
        ],
      },
      setFormVisible: false,
      userForm: {},
      dates: [],
    };
  },
  methods: {
    typeChange () {
      this.getUserList();
    },
    projectChanged (v) {
      if (!v) {
        this.dates = [];
        this.userForm.Address = "";
        this.userForm.Project = null;
      }
    },
    dateChange (v) {
      if (v && v.length) {
        let begin = this.dates.length > 0 ? this.dates[0] : "";
        let end = this.dates.length > 1 ? this.dates[1] : "";
        this.userForm.BeginDate = begin;
        this.userForm.EndDate = end;
      }
    },
    filterTenantChanged(val)
    {
      debugger;
      this.filters.tenant =val;
    },
    tenantChanged (val) {
      this.addForm.Actor = val;
    },
    create () {
      this.dialogStatus = "create";
      this.addFormVisible = true;
      this.readonlyUserName = false;
      this.addForm = {
        Name: "",
        Actor: "",
        RealName: "",
        Email: "",
        Mobile: "",
        Description: "",
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleChange: function (row) {
      save(row).then((response) => {
        if (response) {
          this.getUserList();
        }
      });
    },
    createSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let para = Object.assign({}, this.addForm);
          save(para).then((response) => {
            if (response) {
              this.msg.successMsg("操作成功");
              this.addFormVisible = false;
              this.getUserList();
            }
          });
        }
      });
    },
    update (row) {
      this.addForm = Object.assign({}, row); // copy obj
      if (this.addForm.Name == "administrator") this.readonlyUserName = true;
      else this.readonlyUserName = false;
      this.dialogStatus = "update";
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    updateSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.addForm);
          save(data)
            .then((response) => {
              if (response) {
                this.msg.successMsg("操作成功");
                this.addFormVisible = false;
                this.getUserList();
              }
            })
            .catch((ex) => {
              this.msg.errorMsg("出现错误！");
            });
        }
      });
    },
    remove: function (row) {
      let userName = row.Name;
      if (userName == "administrator") {
        this.msg.successMsg("administrator是超级管理员账号，不能删除！");
        return;
      }
      this.$confirm("确认删除用户" + userName + "吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.listLoading = true;
        let data = { Key: row.Key };
        del(data).then((response) => {
          this.listLoading = false;
          if (response) {
            this.msg.successMsg("操作成功");
            this.getUserList();
          }
        });
      });
    },

    showChangePwdForm (row) {
      this.$refs.pwdForm.showForm(row.Name);
    },

    role (row) {
      this.$refs.userRoleView.show(row.Key);
    },
    power (row) {
      this.$refs.powerManager.showManager(row.Key);
    },
    getUserList: function () {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        tenant:this.filters.tenant?this.filters.tenant.Key:"",
        search: this.filters.search,
        source: this.filters.source,
      };
      this.listLoading = true;
      getPageList(data).then((response) => {
        this.listLoading = false;
        if (response) {
          this.users = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },

    handleSizeChange (val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getUserList());
    },

    handleCurrentChange (val) {
      this.pageIndex = val;
      this.getUserList();
    },

    memberTypeChange (row) {
      let param = {
        memberKey: row.Key,
        memberType: row.MemberType
      }
      changeMemberType(param).then(res => {
        this.msg.successMsg("修改成功")
      })
    }
  },
  mounted () {
    this.getUserList();
  },
};
</script>
<style lang='scss' scoped>
.userinfo-container {
  padding: 10px;
}
/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>
