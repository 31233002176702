<template>
  <el-upload list-type="picture-card" :action="importApiPath" :headers="myHeaders" :on-change="handleLimit" :on-success="handleSuccess" :on-remove="handleRemove" :limit="limitCount" :class="{ disabled: uploadDisabled }" :before-upload="beforeUpload" :file-list="dataList" ref="imageUploader">
    <i class="el-icon-plus" />
  </el-upload>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      default: ''
    },
    limitCount: {
      default: 1
    }
  },
  data () {
    return {
      uploadDisabled: false,
      myHeaders: {},
      apiUrl: process.env.VUE_APP_BASE_API,
      importApiPath: process.env.VUE_APP_BASE_API + '/api/fileUpload/upload',
      imageUrls: [],
      dataList: []
    }
  },
  mounted () {
    this.myHeaders = {
      Authorization: localStorage.getItem('X-Token')
    }
  },
  methods: {
    setData (val) {
      this.dataList = []
      this.imageUrls = []
      if (val && val != '') {
        if (val.indexOf(';') > 0) {
          const urls = val.split(';')
          urls.forEach((item) => {
            const obj = new Object()
            obj.url = this.apiUrl + item
            this.dataList.push(obj)
            this.imageUrls.push(item)
          })
        } else {
          const obj = new Object()
          obj.url = this.apiUrl + val
          this.dataList.push(obj)
          this.imageUrls.push(val)
        }
      }
      if (this.dataList.length >= this.limitCount) {
        this.uploadDisabled = true
      } else {
        this.uploadDisabled = false
      }
    },
    beforeUpload (file) {
      const imglist = ['png', 'jpg', 'jpeg']
      const flieArr = file.type.split('/')
      const result = imglist.indexOf(flieArr[1].toLowerCase())
      let isJPG = true
      if (result === -1) {
        this.$message.error('请上传png、jpg、jpeg格式的图片！')
        isJPG = false
      }
      return isJPG
    },
    handleLimit (file, fileList) {
      if (fileList.length >= this.limitCount) {
        this.uploadDisabled = true
      } else {
        this.uploadDisabled = false
      }
    },
    handleRemove (file, fileList) {
      if (fileList.length < this.limitCount) {
        this.uploadDisabled = false
      }
      const path = file.url.replace(this.apiUrl, '')
      const index = this.imageUrls.indexOf(path)
      if (index >= 0) {
        this.imageUrls.splice(index, 1)
      }
      this.handleChanged()
    },
    handleSuccess (res) {
      this.imageUrls.push(res.Data)
      this.handleChanged()
    },
    handleChanged () {
      const urls = this.imageUrls.join(';')
      this.$emit('change', urls)
    }
  }
}
</script>
<style>
.disabled .el-upload--picture-card {
  display: none !important;
}
</style>