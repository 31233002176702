var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Treeselect", {
    attrs: {
      placeholder: "请选择门店",
      normalizer: _vm.normalizer,
      disableBranchNodes: false,
      showCount: "",
      defaultExpandLevel: 1,
      multiple: false,
      options: _vm.datas,
      disabled: _vm.disabled,
      clearable: _vm.clearable
    },
    on: { select: _vm.selectChanged },
    model: {
      value: _vm.data,
      callback: function($$v) {
        _vm.data = $$v
      },
      expression: "data"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }