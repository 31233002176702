var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dragDialog", rawName: "v-dragDialog" }],
      attrs: {
        title: "所属角色",
        visible: _vm.viewVisible,
        width: "557px",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.viewVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            height: "300px",
            size: "mini",
            data: _vm.datas,
            "highlight-current-row": "",
            "expand-on-click-node": "false",
            "header-row-style": {
              height: "30px;",
              "background-color": "#4682B4",
              color: "#FFFFFF"
            },
            "header-cell-style": {
              height: "30px;",
              "background-color": "#4682B4",
              color: "#FFFFFF"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "角色",
              prop: "Name",
              "min-width": "150",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "授权",
              prop: "Grant",
              "min-width": "150",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      on: {
                        change: function($event) {
                          return _vm.changeGrant(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.Grant,
                        callback: function($$v) {
                          _vm.$set(scope.row, "Grant", $$v)
                        },
                        expression: "scope.row.Grant"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.viewVisible = false
                }
              }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }