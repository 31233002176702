var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "userinfo-container" }, [
    _c(
      "section",
      [
        _c(
          "el-form",
          { attrs: { inline: true, model: _vm.filters } },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { label: "所属门店" }
              },
              [
                _c("shopSelector", {
                  staticStyle: { width: "200px", height: "24px" },
                  attrs: { "auto-complete": "off" },
                  model: {
                    value: _vm.filters.tenant,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "tenant", $$v)
                    },
                    expression: "filters.tenant"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0px" },
                attrs: { label: "关键字" }
              },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: {
                    size: "small",
                    placeholder: "登录名/姓名/手机/昵称/备注等关键词"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getUserList($event)
                    }
                  },
                  model: {
                    value: _vm.filters.search,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "search", $$v)
                    },
                    expression: "filters.search"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticStyle: { "margin-bottom": "0px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.getUserList }
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "success" },
                    on: { click: _vm.create }
                  },
                  [_vm._v("新增")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tabs-container" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.typeChange },
                model: {
                  value: _vm.filters.source,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "source", $$v)
                  },
                  expression: "filters.source"
                }
              },
              _vm._l(_vm.tabs, function(tab) {
                return _c("el-tab-pane", {
                  key: tab.key,
                  attrs: { name: tab.key, label: tab.name }
                })
              }),
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              stripe: "",
              border: "",
              size: "mini",
              data: _vm.users,
              "highlight-current-row": "",
              "expand-on-click-node": "false",
              "header-cell-style": {
                "font-weight": "400",
                height: "40px",
                padding: "0",
                background: "#4a8bc1",
                color: "white"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "Name",
                sortable: "",
                label: "登录名",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "RealName",
                sortable: "",
                label: "真实姓名",
                "min-width": "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "Actor.Name",
                sortable: "",
                label: "所在门店",
                "min-width": "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "Mobile",
                sortable: "",
                label: "手机号",
                "min-width": "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "Email",
                sortable: "",
                label: "邮箱",
                "min-width": "100"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "HeadImage", label: "头像", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("viewer", [
                        scope.row.HeadImage
                          ? _c("img", {
                              key: scope.row.HeadImage,
                              attrs: {
                                src:
                                  scope.row.HeadImage.indexOf("http") == 0
                                    ? scope.row.HeadImage
                                    : _vm.baseUrl + scope.row.HeadImage,
                                width: "30"
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "用户类型", "min-width": "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { size: "small" },
                          on: {
                            change: function($event) {
                              return _vm.memberTypeChange(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.MemberType,
                            callback: function($$v) {
                              _vm.$set(scope.row, "MemberType", $$v)
                            },
                            expression: "scope.row.MemberType"
                          }
                        },
                        _vm._l(_vm.$config.memberType, function(item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { value: item.value, label: item.name }
                          })
                        }),
                        1
                      ),
                      scope.row.MemberType == 10
                        ? _c("el-switch", {
                            attrs: { "active-text": "接收预约提醒" },
                            on: {
                              change: function($event) {
                                return _vm.handleChange(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.Alert,
                              callback: function($$v) {
                                _vm.$set(scope.row, "Alert", $$v)
                              },
                              expression: "scope.row.Alert"
                            }
                          })
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "IsActive",
                sortable: "",
                label: "启用",
                width: "100"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "active-color": "#13ce66" },
                        on: {
                          change: function($event) {
                            return _vm.handleChange(scope.row, "IsActive")
                          }
                        },
                        model: {
                          value: scope.row.IsActive,
                          callback: function($$v) {
                            _vm.$set(scope.row, "IsActive", $$v)
                          },
                          expression: "scope.row.IsActive"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "InsertTime",
                sortable: "",
                label: "创建时间",
                width: "130"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.format.dateTimeFormat(scope.row.InsertTime)
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "250" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.Name != "administrator"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.update(scope.row)
                                }
                              }
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      scope.row.Name != "administrator"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.showChangePwdForm(scope.row)
                                }
                              }
                            },
                            [_vm._v("修改密码")]
                          )
                        : _vm._e(),
                      scope.row.Name != "administrator"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.remove(scope.row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      scope.row.Name != "administrator"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.role(scope.row)
                                }
                              }
                            },
                            [_vm._v("所属角色")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination-container" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                "page-sizes": _vm.pageSizesList,
                "page-size": _vm.pageSize,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                "current-page": _vm.pageIndex
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function($event) {
                  _vm.pageIndex = $event
                },
                "update:current-page": function($event) {
                  _vm.pageIndex = $event
                }
              }
            })
          ],
          1
        ),
        _vm.addFormVisible
          ? _c(
              "div",
              [
                _c(
                  "el-dialog",
                  {
                    directives: [
                      { name: "dragDialog", rawName: "v-dragDialog" }
                    ],
                    attrs: {
                      title: _vm.textMap[_vm.dialogStatus],
                      visible: _vm.addFormVisible,
                      width: "500px",
                      "close-on-click-modal": false
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.addFormVisible = $event
                      }
                    }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "dataForm",
                        attrs: {
                          model: _vm.addForm,
                          "label-width": "130px",
                          rules: _vm.addFormRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "登录名：", prop: "Name" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    readonly: _vm.readonlyUserName,
                                    placeholder: "请输入登录名"
                                  },
                                  model: {
                                    value: _vm.addForm.Name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "Name", $$v)
                                    },
                                    expression: "addForm.Name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "真实姓名：", prop: "RealName" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入真实姓名"
                                  },
                                  model: {
                                    value: _vm.addForm.RealName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "RealName", $$v)
                                    },
                                    expression: "addForm.RealName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属门店：", prop: "Actor" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("tenantSelector", {
                                  attrs: { "auto-complete": "off" },
                                  on: { change: _vm.tenantChanged },
                                  model: {
                                    value: _vm.addForm.Actor,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "Actor", $$v)
                                    },
                                    expression: "addForm.Actor"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.dialogStatus == "create"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "密码：", prop: "Password" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 19 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "password",
                                        "auto-complete": "off",
                                        placeholder: "请输入密码"
                                      },
                                      model: {
                                        value: _vm.addForm.Password,
                                        callback: function($$v) {
                                          _vm.$set(_vm.addForm, "Password", $$v)
                                        },
                                        expression: "addForm.Password"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialogStatus == "create"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "确认密码：",
                                  prop: "Password2"
                                }
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 19 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "password",
                                        "auto-complete": "off",
                                        placeholder: "请确认密码"
                                      },
                                      model: {
                                        value: _vm.addForm.Password2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "Password2",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.Password2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号", prop: "Mobile" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入手机号"
                                  },
                                  model: {
                                    value: _vm.addForm.Mobile,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "Mobile", $$v)
                                    },
                                    expression: "addForm.Mobile"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "邮箱", prop: "Email" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: "请输入邮箱"
                                  },
                                  model: {
                                    value: _vm.addForm.Email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "Email", $$v)
                                    },
                                    expression: "addForm.Email"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：", prop: "Description" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    "auto-complete": "off",
                                    placeholder: "请输入备注"
                                  },
                                  model: {
                                    value: _vm.addForm.Description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.addForm, "Description", $$v)
                                    },
                                    expression: "addForm.Description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.addFormVisible = false
                              }
                            }
                          },
                          [_vm._v("取消")]
                        ),
                        _vm.dialogStatus == "create"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.createSubmit }
                              },
                              [_vm._v("提交")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.updateSubmit }
                              },
                              [_vm._v("提交")]
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c("powerManager", {
          ref: "powerManager",
          attrs: { showOption: true }
        }),
        _c("userRoleView", { ref: "userRoleView" }),
        _c("ChangePwd", { ref: "pwdForm" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }