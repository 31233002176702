var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "imageUploader",
      class: { disabled: _vm.uploadDisabled },
      attrs: {
        "list-type": "picture-card",
        action: _vm.importApiPath,
        headers: _vm.myHeaders,
        "on-change": _vm.handleLimit,
        "on-success": _vm.handleSuccess,
        "on-remove": _vm.handleRemove,
        limit: _vm.limitCount,
        "before-upload": _vm.beforeUpload,
        "file-list": _vm.dataList
      }
    },
    [_c("i", { staticClass: "el-icon-plus" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }